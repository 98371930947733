import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import currentUser$ from 'streams/currentUser/currentUser$';
import featureSettings$ from './featureSettings$';
import { Features, hasFeature } from '@ardoq/features';
import { FeatureSettingsViewModelState } from './types';
import { featureSettingsCommands } from './featureSettingsCommands';
import { PrivilegeLabel } from '@ardoq/api-types';
import { hasPrivilege } from '@ardoq/privileges';

export const featureSettingsViewModel$ = combineLatest([
  currentUser$,
  featureSettings$,
]).pipe(
  map(([_currentUser, { isUpdating }]): FeatureSettingsViewModelState => {
    const hasAzureAmendmentFeature = hasFeature(Features.AZURE_AMENDMENT);
    const shouldDisableAllAiFeatures = isUpdating || !hasAzureAmendmentFeature;
    const hasBoughtChangeApprovalFeature =
      !hasFeature(Features.SURVEYS_CHANGE_APPROVAL_TRIAL) &&
      hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2);

    return {
      isUpdating,
      isAiSectionDisabled: !hasAzureAmendmentFeature,
      dataGovernanceFeatures: {
        surveyChangeApprovalTrial: {
          // everyone has the trial feature enabled by default
          // triggering the trial ON turns on the V2 feature
          isChecked:
            hasFeature(Features.SURVEYS_CHANGE_APPROVAL_TRIAL) &&
            hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2),
          isDisabled:
            !hasPrivilege(PrivilegeLabel.ACCESS_DISCOVER) ||
            hasFeature(Features.SURVEYS_CHANGE_APPROVAL_TRIAL_EXPIRED),
          isHidden: hasBoughtChangeApprovalFeature,
          persistedId: Features.SURVEYS_CHANGE_APPROVAL_V2.persistedId,
        },
      },
      aiSectionFeatures: {
        azureOpenaiBestPracticeAssistant: {
          isChecked: hasFeature(Features.AZURE_OPENAI_BEST_PRACTICE_ASSISTANT),
          isDisabled: shouldDisableAllAiFeatures,
          isHidden: false,
          persistedId:
            Features.AZURE_OPENAI_BEST_PRACTICE_ASSISTANT.persistedId,
        },
        azureAutoCompleteOpenai: {
          isChecked: hasFeature(Features.AUTOCOMPLETE_OPENAI),
          isDisabled: shouldDisableAllAiFeatures,
          isHidden: false,
          persistedId: Features.AUTOCOMPLETE_OPENAI.persistedId,
        },
        azureOpenAIViewInference: {
          isChecked: hasFeature(Features.AZURE_OPENAI_VIEW_INFERENCE),
          isDisabled: shouldDisableAllAiFeatures,
          isHidden: false,
          persistedId: Features.AZURE_OPENAI_VIEW_INFERENCE.persistedId,
        },
        azureOpenAIComponentLinkingSuggestions: {
          isChecked: hasFeature(
            Features.AZURE_OPENAI_COMPONENT_LINKING_SUGGESTIONS
          ),
          isDisabled: shouldDisableAllAiFeatures,
          isHidden: false,
          persistedId:
            Features.AZURE_OPENAI_COMPONENT_LINKING_SUGGESTIONS.persistedId,
        },
        lucidVisualImporter: {
          isChecked: hasFeature(Features.LUCID_IMPORT_ENABLE),
          isDisabled: shouldDisableAllAiFeatures,
          isHidden: true,
          persistedId: Features.LUCID_IMPORT_ENABLE.persistedId,
        },
      },
      easeOfUseFeature: {
        isChecked:
          hasFeature(Features.SUPPORT_LARGE_DATASETS) &&
          hasFeature(Features.NEW_CORE_JOURNEY),
        isDisabled: false,
        isHidden: false,
        persistedId: '',
      },
      commands: featureSettingsCommands,
    };
  })
);
